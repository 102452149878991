<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">Username</label>
      <div class="col-3">
        {{ username }}
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">Email</label>
      <div class="col-3">
        {{ email }}
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">Secret Key</label>
      <div class="col-6">
            <input type="text" v-model="secretKey" class="form-control" placeholder="Optional. Used to encode and decode sensitive data, never saved in server"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">Current Password</label>
      <div class="col-3">
            <input type="text" v-model="currentPassword" class="form-control" placeholder="Optional. Required if change password"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">New Password</label>
            <div class="col-3">
            <input type="text" v-model="password" class="form-control" placeholder="Optional. Required if change password"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-2 col-form-label">Confirm Password</label>
            <div class="col-3">
            <input type="text" v-model="confirmPassword" class="form-control" placeholder="Optional. Required if change password"/>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-2 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "Profile",
  data: function () {
    return {
      id: "",
      breadcrumbs: [{ name: "Profile"}],
      alertContent: "",
      alertClass: "alert-danger",
      username: localStorage.getItem("username")|| "",
      email: "",
      secretKey: localStorage.getItem("secretKey"),
      currentPassword: "",
      password: "",
      confirmPassword: ""
    };
  },
  mounted() {
      if (this.username) {
         this.getObject();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      if (this.secretKey) {
        localStorage.setItem("secretKey", this.secretKey);
        this.alertClass = "alert-success";
        this.alertContent = "Your secret key has been saved into your browser successfully!";
      } else {
        localStorage.removeItem("secretKey");
        this.alertClass = "alert-success";
        this.alertContent = "Your secret key has been removed from your browser successfully!";
      }
      if (this.currentPassword && this.password && this.confirmPassword && this.password === this.confirmPassword) {
        this.submitted = true;
        axios.post("/user/" + this.id, {currentPassword: this.currentPassword, password: this.password})
          .then((response) => {
            this.submitted = false;
            this.alertClass = "alert-success";
            this.alertContent = response.data;
          })
          .catch(() => {
            this.alertClass = "alert-danger";
            this.alertContent = "Your password was not updated, current password is incorrect!";
            this.submitted = false;
          });
      } else if (this.password || this.confirmPassword || this.currentPassword) {
        this.alertClass = "alert-danger";
        this.alertContent = "Your password was not updated, please check your inputs!";
      }
    },
    getObject() {
      axios
      .get("/user/" + this.username)
      .then((response) => {
        this.email = response.data.email;
        this.id = response.data.id;
      })
      .catch((error) => {
        this.alertClass = "alert-danger";
        this.alertContent = error.response ? error.response.data : error;
      });
    }
  },
};
</script>
